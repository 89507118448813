$(document).ready(function () {

    function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return (
            rect.top >= 0 &&
            rect.bottom <= windowHeight
        );
    }

    function handleScroll() {
        var fadeElementsLeft = $('.fade-from-left');
        fadeElementsLeft.each(function () {
            var el = $(this);
            if (isElementInViewport(this)) {
                el.addClass('fade-enter');
            }
        });

        var fadeElementsRight = $('.fade-from-right');
        fadeElementsRight.each(function () {
            var el = $(this);
            if (isElementInViewport(this)) {
                el.addClass('fade-enter');
            }
        });

        var element = $(".address_link i");
        if (isElementInViewport(element[0])) {
            element.addClass("bounceIn");
        }
    }

    // Ejecutar handleScroll() al cargar la página
    handleScroll();
    $(window).on('scroll resize', handleScroll); // Agregar evento de redimensionamiento

});
